<template>
  <div class="row justify-content-center align-items-md-center">
  <div class="col-md-5 col-12 col-lg-4 col-sm-6">
    <h1>Documento Eletrônico</h1>
  </div>
    <div class=" col-md-12 row align-items-md-center justify-content-center mt-1">
 <div class=" row col-md-7 col-sm-7 col-lg-6 col-12 justify-content-between ">
      <v-btn @click="addEditor" class="mt-4">Adicionar Editor</v-btn>
      <div>
        <label for="">Adicionar arquivo</label>
        <br>
      <input type="file" @change="addFile" accept=".pdf, image/*" />
    </div>
    </div>
  </div>
    <div class="col-md-12">
      <div class="lista" v-for="(item, index) in items" :key="index">
        <div v-if="item.type === 'file'">
          <div class="d-flex justify-end mt-3">
            <v-btn
              small
              @click="excluirCupdoc(item)"
              color="error"
              class="mb-2"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <v-img v-if="!item.isPDF" :src="item.content" max-width="400"></v-img>
          <div v-else>
            <v-card :loading="loading">
              <v-pagination
                v-model="item.currentPage"
                :length="item.totalPages"
                @input="goToPage(index)"
              ></v-pagination>
              <vue-pdf
                :src="item.file"
                :width="400"
                :height="500"
                :page="item.currentPage"
                @num-pages="onNumPages(index, $event)"
              ></vue-pdf>
            </v-card>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-end mt-3">
            <v-btn
              small
              @click="excluirCupdoc(item)"
              color="error"
              class="mb-2"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <v-card>
            <v-card-title>Editor de Texto</v-card-title>
            <v-card-text>
              <vue-editor
                v-model="item.content"
                :editor-options="editorOptions"
              ></vue-editor>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div class="row justify-content-between align-items-md-center">
      <v-btn @click="saveAllItems()" color="primary">Salvar</v-btn>
      <v-btn @click="juntar()" color="info">Juntar</v-btn>
    </div>
  </div>
</template>
  
  <script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import dateFormat from "@/core/mixins/dateFormat";
import { VueEditor } from "vue2-editor";
import VuePdf from "vue-pdf"; // Importe o componente VuePdf
import axios from "axios";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert, dateFormat],

  components: {
    VueEditor,
    VuePdf, // Registre o componente VuePdf
  },
  data() {
    return {
      items: [], // Array para armazenar itens (arquivos, PDFs ou editores)
      editorOptions: {
        placeholder: "Digite seu texto aqui...",
      },
      allItemsJSON: [],
      loading: false,
    };
  },
  computed: {
    lista_cupdoc() {
      return this.$store.state.cupdoc.lista_cupdoc;
    },
    mensagem_alert() {
      return this.$store.state.cupdoc.mensagem_alert;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Documentos Eletronicos" }]);
    this.listar_cupdoc();
  },
  methods: {
    async listar_cupdoc() {
      await this.$store.dispatch("cupdoc/listar_cupdoc");
      this.items = this.lista_cupdoc;
      //  console.log(this.items)
    },
    saveItem() {
      this.items.map((item, index) => {
        if (item.type === "editor") {
          // Se o item for um editor de texto, salve seu conteúdo
          const editorContent = item.content;
          this.addToAllItemsJSON({
            id: item.id ? item.id : null,
            type: "editor",
            content: editorContent,
            sequencia: index,
          });
        } else if (item.type === "file") {
          // Se o item for um arquivo (PDF ou imagem), salve o conteúdo do arquivo
          const fileContent = item.file;
          this.addToAllItemsJSON({
            id: item.id ? item.id : null,
            type: "file",
            content: fileContent,
            sequencia: index,
            filename: item.fileName,
            mimi: item.mimi,
          });
        }
      });
    },
    addToAllItemsJSON(itemData) {
      // Adicione o item ao JSON geral
      this.allItemsJSON.push(itemData);
    },

    async saveAllItems() {
      this.allItemsJSON = [];
      this.saveItem();
      await this.sleep(100);
      let data = [];
      // console.log(this.allItemsJSON);

      // Envie formData para o servidor usando uma solicitação HTTP, como o Axios ou o fetch.
      await this.$store.dispatch("cupdoc/create_cupdoc", this.allItemsJSON);
      this.fireAlert(this.mensagem_alert);

      // Agora você pode enviar formData para o servidor usando uma solicitação HTTP, como o Axios ou o fetch.
    },

    async juntar() {
      await this.$store.dispatch("cupdoc/juntar_cupdoc");

      this.fireAlert(this.mensagem_alert);
    },
    addFile(event) {
      // Armazena a referência do objeto atual (this) para usá-la dentro da função de callback
      const self = this;

      // Adicione um novo item de arquivo à lista
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          // O resultado é a representação em base64 do arquivo
          const base64String = e.target.result;

          // Faça o que você precisa com a representação em base64 (por exemplo, envie para o servidor)
          console.log(base64String);

          // Adicione o item à lista usando a referência do objeto atual (this)
          const isPDF = file.type === "application/pdf";
          // const content = URL.createObjectURL(file);
          const fileName = file.name; // Obtém o nome do arquivo
          self.items.push({
            type: "file",
            // content: content,
            fileName: fileName, // Armazena o nome do arquivo
            file: base64String,
            isPDF: isPDF,
            mimi: file.type,
            currentPage: 1, // Página inicial
            totalPages: null, // Será definido quando o documento for carregado
          });
        };

        // Lê o arquivo como base64
        reader.readAsDataURL(file);
      }
    },

    addEditor() {
      // Adicione um novo editor de texto à lista
      this.items.push({
        type: "editor",
        content: "", // Inicialize com um conteúdo vazio
      });
    },

    async excluirCupdoc(value) {
      await this.$store.dispatch("cupdoc/delete_cupdoc", value);
      this.fireAlert(this.mensagem_alert);
      this.items = this.lista_cupdoc;
    },

    saveFile(index) {
      // Salvar o conteúdo do editor de texto (índice passado como argumento)
      const contentToSave = this.items[index].content;
      // Faça algo com o conteúdo, por exemplo, envie para um servidor
      console.log("Salvando conteúdo:", contentToSave);
    },
    async goToPage(index) {
      // Atualize a página atual do PDF quando a paginação for alterada
      this.loading = true;
      await this.sleep(300);

      const item = this.items[index];
      item.currentPage = item.currentPage; // Atualize a página atual
      this.loading = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    onNumPages(index, numPages) {
      // Este método é chamado quando o evento @num-pages é acionado
      // Ele recebe o número total de páginas do PDF como argumento
      const item = this.items[index];
      item.totalPages = numPages;
    },
  },
};
</script>
  
  <style scoped>
.lista {
  margin-bottom: 20px;
}

/* .lista:hover {
  background-color: rgba(255, 255, 255, 0.705);
} */
</style>
  